import React, {
    useState,
    useContext,
    createContext,
    useCallback,
    useEffect,
    useRef,
} from 'react';

import I18N from 'i18n-js';

const LangContext = createContext(null);

const LangProvider = ({ children, defaultLocale, translations }) => {
    const [locale, setLocale] = useState(defaultLocale);
    const previousLang = useRef(locale);

    I18N.translations = translations;

    I18N.defaultLocale = locale;
    I18N.locale = locale;

    useEffect(() => {
        if (previousLang?.current !== defaultLocale) {
            setLocale(defaultLocale);

            previousLang.current = defaultLocale;
        }
    }, [defaultLocale]);

    return (
        <LangContext.Provider value={[I18N, locale]}>
            {children}
        </LangContext.Provider>
    );
};

// Hooks
function useTranslate() {
    const [translation, locale] = useContext(LangContext);

    const t = useCallback(
        (key: string, props: { [key: string]: unknown } = {}) => {
            const value = translation.t(key, { locale: locale, ...props });
            return value;
        },
        [translation, locale],
    );

    return [t, locale];
}

export { LangProvider, useTranslate, LangContext };
