import '@solublestudio/legalbrave-design-system/src/scss/cookiebot.scss';

import { ReactNode, useEffect } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { UserProvider } from 'api-base/auth';
import { LangProvider } from 'cms-base/hooks/useTranslate';
import Script from 'next/script';

import { clarity } from 'react-microsoft-clarity';

export type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactNode, pageProps?: any) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
    const getLayout = Component.getLayout || ((page) => page);
    const { query, isReady } = useRouter();
    const shouldShow =
        process.env.GTM_ID &&
        isReady &&
        !query['hide-gtm'] &&
        !query['hide-all'];

    useEffect(() => {
        if (process.env.NEXT_PUBLIC_MICROSOFT_CLARITY) {
            clarity.init(process.env.NEXT_PUBLIC_MICROSOFT_CLARITY);
        }
    }, []);

    return (
        <>
            <LangProvider
                defaultLocale={pageProps?.locale}
                translations={pageProps?.translations}>
                <UserProvider>
                    {getLayout(<Component {...pageProps} />, pageProps)}
                </UserProvider>
            </LangProvider>
            {shouldShow ? (
                <Script
                    id="gag"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '${
                                    process.env.GTM_AUTH
                                        ? `&gtm_auth=${process.env.GTM_AUTH}`
                                        : ''
                                }${
                            process.env.GTM_PREVIEW
                                ? `&gtm_preview=${process.env.GTM_PREVIEW}`
                                : ''
                        }&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
                                })(window,document,'script','dataLayer','${
                                    process.env.GTM_ID
                                }');`,
                    }}
                />
            ) : null}
            {shouldShow ? (
                <noscript
                    dangerouslySetInnerHTML={{
                        __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${
                            process.env.GTM_ID
                        }${
                            process.env.GTM_AUTH
                                ? `&gtm_auth=${process.env.GTM_AUTH}`
                                : ''
                        }${
                            process.env.GTM_PREVIEW
                                ? `&gtm_preview=${process.env.GTM_PREVIEW}`
                                : ''
                        }&gtm_cookies_win=x"
                                height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                    }}
                />
            ) : null}
            <Script
                strategy="beforeInteractive"
                id="Cookiebot"
                data-culture={pageProps?.locale ?? 'en'}
                src="https://consent.cookiebot.com/uc.js"
                data-cbid="40c32160-091b-4436-8579-a7c494647873"
                data-blockingmode="auto"
                type="text/javascript"
                defer
            />
            <Script
                strategy="beforeInteractive"
                id="CookieDeclaration"
                src="https://consent.cookiebot.com/40c32160-091b-4436-8579-a7c494647873/cd.js"
                type="text/javascript"
                defer
            />
        </>
    );
}

export default MyApp;
